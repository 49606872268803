import { post, deletes, get, downFile } from 'common/request'
export default {
  /**
   * 获取
   */
  // 获取走访慰问列表数据
  list: params => get('/interviewInfo/list', params),
  // 获取特殊妇女列表数据
  womanList: params => get('/specialGroup/woman/list', params),
  // 获取特殊儿童列表数据
  childrenList: params => get('/specialGroup/children/list', params),
  // 获取空巢老人列表数据
  elderlyList: params => get('/specialGroup/elderly/list', params),
  // 获取退役军人列表数据
  veteranList: params => get('/specialGroup/veteran/list', params),
  // 获取精神病患者列表数据
  psychopathList: params => get('/keyPopulation/psychopath/list', params),
  // 获取刑满释放人员列表数据
  emancipistlist: params => get('/keyPopulation/emancipist/list', params),
  // 获取社区矫正人员列表数据
  rectificationList: params => get('/keyPopulation/rectification/list', params),
  // 获取吸毒人员列表数据
  drugList: params => get('/keyPopulation/drug/list', params),
  // 获取涉邪人员列表数据
  cultList: params => get('/keyPopulation/cult/list', params),
  // 获取不良行为青少年列表数据
  minorsList: params => get('/keyPopulation/minors/list', params),
  // 获取重点维稳信访人员列表数据
  petitionList: params => get('/keyPopulation/petition/list', params),
  // 根据id获取人口详情
  getResidentArchivesById: id => get(`/residentArchives/getById?id=${id}`),
  // 根据id获取走访信息详情
  getInterviewInfoById: id => get(`/interviewInfo/getById?id=${id}`),
  // 获取省的行政区划
  getRegionInfo: args => get(`/regionInfo/list?level=1`),
  // 获取市级的行政区划
  getProvinceRegionInfo: provinceCode => get(`/regionInfo/list?level=2&&provinceCode=${provinceCode}`),
  // 获取区级的行政区划
  getCityRegionInfo: cityCode => get(`/regionInfo/list?level=3&&cityCode=${cityCode}`),
  // 获取县级的行政区划
  getCountyRegionInfo: countyCode => get(`/regionInfo/list?level=4&&countyCode=${countyCode}`),
  // 获取所有小区
  getGridManagement: args => get('/scGridManagement/queryAll', args),
  /**
   * 插入/修改
   */
  // 新增走访记录
  addInterviewInfo: args => post('/interviewInfo/add', args),
  // 导出
  exports: args => downFile('/residentArchives/exportXls', args),
  // 根据id删除
  deleteInterviewInfoById: id => deletes(`/interviewInfo/deleteById?id=${id}`),
  // 批量删除
  deleteInterviewInfoByIds: ids => deletes(`/interviewInfo/ids?ids=${ids}`),
  // 根据id编辑人口信息
  updateInterviewInfo: args => post('/interviewInfo/update', args),
  // 删除图片
  del: args => get(`/file/del/?id=${args}`),

  /**
     * 字典
     */
  // form里面的下拉获取数据字典
  dictData: args => get('/dict-data/getByDicKind', args),
}
